$primary: #0082C3;
$secondary: #57585A;
$tertiary: #EF7D00;
$body-color: #000;
$headings-color: $secondary;
$link-color: $secondary;
$border-color: #dbdbdb;
$grey-color: #AAA;
$icon-color: #AAA;
$search-home-bg-color: lighten($border-color,5%);
$advantage-color: #598527;
$stars-color: #898989;
$legenda-available-color: #39b54a;
$legenda-blocked-color: #bbb;
$legenda-available-arrival-date-color: $secondary;
$legenda-selected-arrival-date-color: $tertiary;
$top_strip_text_color: black;
$call_to_action_bg: $tertiary;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";

#header > .header a{
  color:$primary;
  &:hover{
    color:darken($primary, 10);
  }
}
